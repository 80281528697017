import type { Template } from '../template'
import { PROGRAM_STATUS } from '../template'

const fontFace = `
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Roboto'), url(/fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Roboto'), url(/fonts/Roboto-Bold.ttf) format('truetype');
}
`

export const Koffie: Template = {
  usePrequal: false,
  gradient: 'linear-gradient(to bottom, #000000 19%, #333333);',
  cardOrientation: 'horizontal',
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [],
  copy: {
    subdomain: 'koffie',
    brandName: 'Koffie',
    cardName: 'Visa® Business Card',
    bankPartner: 'Emprise Bank',
    email: 'koffie@example.com',
    links: {
      faq: 'https://koffiecardsupport.zendesk.com/hc/en-us',
      homepage: 'https://fleet.getkoffie.com/',
      legal: {
        loyaltyProgram:
          'https://static.usepower.com/aea51522-e4fe-4e7f-bd60-1e533b17f5e8/loyalty_program_rules.pdf',
        autopayAgreement:
          'https://static.usepower.com/aea51522-e4fe-4e7f-bd60-1e533b17f5e8/autopay_terms.pdf',
        cardholderAgreement:
          'https://static.usepower.com/aea51522-e4fe-4e7f-bd60-1e533b17f5e8/cardholder_agreement.pdf',
        ecommDisclosure:
          'https://static.usepower.com/aea51522-e4fe-4e7f-bd60-1e533b17f5e8/ecomm_disclosure.pdf',
        autodialerAuth:
          'https://static.usepower.com/aea51522-e4fe-4e7f-bd60-1e533b17f5e8/autodialer_authorization.pdf',
        creditReportAuth:
          'https://static.usepower.com/aea51522-e4fe-4e7f-bd60-1e533b17f5e8/credit_report_authorization.pdf',
        welcomeBonus: '',
      },
    },
    images: {
      logo: {
        src: '/brand/images/koffie/koffie-logo.svg',
        width: 120,
        height: 50,
      },
      cardFrontSrc: '/brand/images/koffie/koffie-front.svg',
    },
    application: {
      title: ['Get free trips with a premium credit card'],
      incentives: [
        {
          title: '1.5%',
          copy: `Cash back on all purchases`,
        },
        {
          title: '2x',
          copy: `Cash back boost at preferred partners`,
        },
      ],
      marginLow: 9.99,
      marginHigh: 22.99,
      feesRows: [
        {
          title: 'Annual Fee',
          explanation: '$0',
        },
        {
          title: 'Foreign Transaction Fee',
          explanation: '3% of the amount of each transaction',
        },
        {
          title: 'Late Payment Fee',
          explanation: '$40',
        },
        {
          title: 'Returned Payment Fee',
          explanation: '$40',
        },
      ],
    },
    rewards: [],
    phoneNumber: '+1.844.387.3822',
    offers: [],
    benefits: [],
  },
  theme: {
    palette: {
      primary: { main: '#000000', light: '#7D7D7D' },
      secondary: {
        main: '#FFFFFF',
      },
      accent: {
        main: '#000000',
      },
      neutral: {
        light: '#E8E8E8',
        main: '#7D7D7D',
        dark: '#535353',
      },
      error: {
        main: '#d60b09',
      },
      brand: {
        main: '#0047BB',
      },
      warning: {
        main: '#E5EDF8',
        light: '#E5EDF8',
      },
      background: {
        default: '#FFFFFF',
        light: '#E5EDF8',
      },
      text: {
        disabled: '#757575',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      h1: {
        fontWeight: 700,
        fontSize: 40,
        lineHeight: '50px',
        letterSpacing: 0,
      },
      h2: {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: '40px',
        letterSpacing: 0,
      },
      h3: {
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '32px',
        letterSpacing: 0,
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: 0,
      },
      subtitle2: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '26px',
        letterSpacing: 0,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: 0,
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '26px',
        letterSpacing: 0,
      },
      overline: {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0,
      },
      caption: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '20px',
        letterSpacing: 0,
      },
      button: {
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 1,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: fontFace,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#0047BB',
          },
        },
      },
    },
  },
}
