import {
  createDefaultLinks,
  createDefaultOffers,
  createDefaultRewards,
  createTypographyStyle,
} from '~/constants/templates/utils'
import type { Template } from '../template'
import { PROGRAM_STATUS } from '../template'

const BRAND_NAME = 'EasyCard'

const fontFace = `
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Mulish'), url(/fonts/Mulish-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Mulish'), url(/fonts/Mulish-Bold.ttf) format('truetype');
}
`

export const ITS: Template = {
  usePrequal: false,
  gradient: 'linear-gradient(90deg, #48BEFF 0%, #0278CA 100%)',
  cardOrientation: 'horizontal',
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [],
  copy: {
    subdomain: 'its',
    brandName: BRAND_NAME,
    cardName: 'Visa® Business Card',
    bankPartner: 'Emprise Bank',
    email: 'its@example.com',
    links: createDefaultLinks(),
    images: {
      logo: {
        src: '/brand/images/its/logo.png',
        width: 128,
        height: 68,
      },
      cardFrontSrc: '/brand/images/its/card.svg',
    },
    application: {
      title: ['Get free trips with a premium credit card'],
      incentives: [
        {
          title: '1.5%',
          copy: 'Cash back on all purchases.',
        },
      ],
      marginLow: 9.99,
      marginHigh: 22.99,
      feesRows: [
        {
          title: 'Annual Fee',
          explanation: '$99',
        },
        {
          title: 'Foreign Transaction Fee',
          explanation: '3% of the amount of each transaction',
        },
        {
          title: 'Late Payment Fee',
          explanation: '$25',
        },
        {
          title: 'Returned Payment Fee',
          explanation: '$25',
        },
      ],
    },
    rewards: createDefaultRewards(),
    offers: createDefaultOffers(BRAND_NAME),
    phoneNumber: '+1.831.228.3307',
    benefits: [],
  },
  theme: {
    palette: {
      primary: {
        main: '#202020',
      },
      secondary: {
        main: '#FFFFFF',
      },
      accent: {
        main: '#21D703',
      },
      neutral: {
        main: '#B5B6BA',
        light: '#DADADC',
        dark: '#7D7F86',
      },
      error: {
        main: '#D60B09',
        light: '#FDEDED',
        dark: '#9C0000',
      },
      warning: {
        main: '#ED8916',
        light: '#FFB94D',
        dark: '#B55B00',
      },
      info: {
        main: '#0278CA',
        light: '#6881FF',
        dark: '#002DB9',
      },
      success: {
        main: '#00b961',
        light: '#5aec90',
        dark: '#008835',
      },
      brand: {
        main: '#48BEFF',
        light: '#5ac4ff',
      },
      background: {
        default: '#FFFFFF',
        light: '#F2F2F7',
      },
    },
    typography: {
      fontFamily: 'Mulish',
      h1: createTypographyStyle(700, 40, '50px'),
      h2: createTypographyStyle(700, 32, '40px'),
      h3: createTypographyStyle(700, 24, '32px'),
      subtitle1: createTypographyStyle(700, 16, '24px'),
      subtitle2: createTypographyStyle(700, 14, '26px'),
      body1: createTypographyStyle(400, 16, '24px'),
      body2: createTypographyStyle(400, 14, '26px'),
      overline: createTypographyStyle(700, 12, '16px'),
      caption: createTypographyStyle(400, 12, '20px'),
      button: {
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 1.5,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: fontFace,
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#48BEFF',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: 'none',
            padding: '12px',
          },
          containedPrimary: {
            backgroundColor: '#48BEFF',
            ':hover': {
              backgroundColor: '#0278CA',
            },
          },
        },
      },
    },
  },
}
